// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/background/login-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".auth-login[data-v-a29f35e4]{position:relative;width:100vw;height:100vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover}.auth-login .login-box[data-v-a29f35e4]{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:400px;padding:20px 30px 50px 30px;background:#fff;border-radius:5px;box-shadow:0 0 10px rgba(0,0,0,.1)}.auth-login .login-box .logo[data-v-a29f35e4]{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;margin-bottom:20px;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center}.auth-login .login-box .logo img[data-v-a29f35e4]{width:88px}.auth-login .login-box .logo span[data-v-a29f35e4]{margin-left:10px;font-size:30px;font-weight:700;color:#d92821}.auth-login .login-box .login-button[data-v-a29f35e4]{width:100%}.auth-login .login-box .reset-button[data-v-a29f35e4]{display:block;margin-top:20px;text-align:center;font-size:14px;color:#333;cursor:pointer;-webkit-transition:all .2s ease;transition:all .2s ease}.auth-login .login-box .reset-button[data-v-a29f35e4]:hover{color:#409eff}", ""]);
// Exports
module.exports = exports;
